import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconGuestPassCustom(props: IconProps) {
	return (
		<Icon viewBox="0 0 289 171" {...props}>
			<path
				fill="url(#a)"
				d="M246.025 102.739c-17.912 22.059-37.213 30.246-61.891 21.343-24.677-8.903-32.971-16.811-61.143-1.956-28.172 14.856-62.073 37.214-80.573 8.839-18.5-28.375 17.934-51.108 35.226-75.603 17.292-24.496 29.412-56.388 63.794-44.642 34.381 11.745 28.279 39.96 68.87 41.34 43.487 1.463 59.668 21.182 35.717 50.679Z"
				opacity=".66"
			/>
			<path
				fill="url(#b)"
				d="M233.788 103.188c-15.967 19.665-33.174 26.964-55.179 19.034-22.006-7.93-29.402-14.994-54.517-1.742-25.116 13.253-55.35 33.174-71.842 7.877C35.76 103.06 68.24 82.785 83.66 60.94c15.423-21.845 26.217-50.274 56.88-39.8C171.202 31.614 165.752 56.772 201.939 58c38.775 1.293 53.203 18.885 31.849 45.187Z"
				opacity=".66"
			/>
			<path fill="#fff" stroke="#000" stroke-width="1.069" d="M66.878 125.578h153.9v37.406h-153.9z" />
			<mask id="c" fill="#fff">
				<path d="M66.343 87.637h154.969v38.475H66.343V87.637Z" />
			</mask>
			<path fill="#E6F2F3" d="M66.343 87.637h154.969v38.475H66.343V87.637Z" />
			<path
				fill="#000"
				d="M66.343 87.637V86.57h-1.069v1.068h1.07Zm154.969 0h1.069V86.57h-1.069v1.068Zm0 38.475v1.069h1.069v-1.069h-1.069Zm-154.969 0h-1.069v1.069h1.07v-1.069Zm0-37.406h154.969V86.57H66.343v2.137Zm153.9-1.069v38.475h2.138V87.637h-2.138Zm1.069 37.407H66.343v2.137h154.969v-2.137Zm-153.9 1.068V87.637h-2.138v38.475h2.138Z"
				mask="url(#c)"
			/>
			<path fill="#027F88" d="M74.893 97.256h21.375v21.375H74.893z" />
			<path fill="url(#d)" d="M74.893 97.256h21.375v21.375H74.893z" />
			<path
				stroke="#fff"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.069"
				d="M87.384 111.684c0-1.18-1.435-2.137-3.206-2.137-1.77 0-3.206.957-3.206 2.137m8.55-1.603v-1.603m0 0v-1.603m0 1.603h-1.603m1.603 0h1.603m-6.947-.534a2.138 2.138 0 1 1 0-4.275 2.138 2.138 0 0 1 0 4.275Z"
			/>
			<mask id="e" fill="#fff">
				<path d="M66.343 50.231h154.969v38.475H66.343V50.231Z" />
			</mask>
			<path fill="#fff" d="M66.343 50.231h154.969v38.475H66.343V50.231Z" />
			<path
				fill="#000"
				d="M66.343 50.231v-1.069h-1.069v1.07h1.07Zm154.969 0h1.069v-1.069h-1.069v1.07Zm0 38.475v1.069h1.069v-1.069h-1.069Zm-154.969 0h-1.069v1.069h1.07v-1.069Zm0-37.406h154.969v-2.138H66.343V51.3Zm153.9-1.069v38.475h2.138V50.231h-2.138Zm1.069 37.406H66.343v2.138h154.969v-2.138Zm-153.9 1.07V50.23h-2.138v38.475h2.138Z"
				mask="url(#e)"
			/>
			<path fill="#027F88" d="M74.893 59.85h21.375v21.375H74.893z" />
			<path fill="url(#f)" d="M74.893 59.85h21.375v21.375H74.893z" />
			<path fill="#E6F2F3" d="M105.887 64.125h95.119v5.344h-95.119zM105.887 72.675h50.231v5.344h-50.231z" />
			<path fill="#027F88" d="M105.887 100.462h95.119v5.344h-95.119z" />
			<path fill="url(#g)" d="M105.887 100.462h95.119v5.344h-95.119z" />
			<path fill="#027F88" d="M105.887 109.012h50.231v5.344h-50.231z" />
			<path fill="url(#h)" d="M105.887 109.012h50.231v5.344h-50.231z" />
			<path fill="#E6F2F3" d="M105.887 137.869h95.119v5.344h-95.119zM105.887 146.419h50.231v5.344h-50.231z" />
			<path fill="#027F88" d="M74.893 134.663h21.375v21.375H74.893z" />
			<path fill="url(#i)" d="M74.893 134.663h21.375v21.375H74.893z" />
			<path
				stroke="#fff"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.069"
				d="M87.384 149.091c0-1.181-1.435-2.138-3.206-2.138-1.77 0-3.206.957-3.206 2.138m8.55-1.603v-1.604m0 0v-1.603m0 1.603h-1.603m1.603 0h1.603m-6.947-.534a2.137 2.137 0 1 1 0-4.275 2.137 2.137 0 0 1 0 4.275ZM87.384 74.278c0-1.18-1.435-2.137-3.206-2.137-1.77 0-3.206.957-3.206 2.137m8.55-1.603v-1.603m0 0v-1.603m0 1.603h-1.603m1.603 0h1.603m-6.947-.535a2.138 2.138 0 1 1 0-4.275 2.138 2.138 0 0 1 0 4.275Z"
			/>
			<path stroke="#000" stroke-width="1.069" d="M47.105 162.984h195.582" />
			<path
				fill="#fff"
				d="m197.985 102.776-1.293 14.232c-.192 2.107-.289 3.17-.113 4.179.157.901.47 1.768.926 2.561.513.891 1.271 1.649 2.779 3.157l20.216 20.216c3.075 3.074 4.613 4.612 6.391 5.19a7.818 7.818 0 0 0 4.831.001c1.783-.579 3.333-2.128 6.427-5.221l15.467-15.468c3.094-3.093 4.639-4.64 5.218-6.423a7.812 7.812 0 0 0-.003-4.829c-.58-1.784-2.122-3.33-5.215-6.424L233.45 93.781c-1.522-1.522-2.283-2.282-3.178-2.798a7.818 7.818 0 0 0-2.561-.928c-1.018-.176-2.09-.08-4.233.116L209.3 91.46c-3.691.335-5.537.504-6.981 1.307a7.803 7.803 0 0 0-3.03 3.03c-.799 1.437-.966 3.27-1.298 6.924l-.006.055Z"
			/>
			<path fill="#fff" d="M217.378 110.853a3.907 3.907 0 1 0-5.526-5.524 3.907 3.907 0 0 0 5.526 5.524Z" />
			<path
				stroke="#222"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.736"
				d="m197.985 102.776-1.293 14.232c-.192 2.107-.289 3.17-.113 4.179.157.901.47 1.768.926 2.561.513.891 1.271 1.649 2.779 3.157l20.216 20.216c3.075 3.074 4.613 4.612 6.391 5.19a7.818 7.818 0 0 0 4.831.001c1.783-.579 3.333-2.128 6.427-5.221l15.467-15.468c3.094-3.093 4.639-4.64 5.218-6.423a7.812 7.812 0 0 0-.003-4.829c-.58-1.784-2.122-3.33-5.215-6.424L233.45 93.781c-1.522-1.522-2.283-2.282-3.178-2.798a7.818 7.818 0 0 0-2.561-.928c-1.018-.176-2.09-.08-4.233.116L209.3 91.46c-3.691.335-5.537.504-6.981 1.307a7.803 7.803 0 0 0-3.03 3.03c-.799 1.437-.966 3.27-1.298 6.924l-.006.055Z"
			/>
			<path
				stroke="#222"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.736"
				d="M217.378 110.853a3.907 3.907 0 1 0-5.526-5.524 3.907 3.907 0 0 0 5.526 5.524Z"
			/>
			<defs>
				<linearGradient id="a" x1="225.944" x2="54.155" y1="26.916" y2="147.133" gradientUnits="userSpaceOnUse">
					<stop stop-color="#9ACCCF" />
					<stop offset=".659" stop-color="#B4D8DB" />
					<stop offset="1" stop-color="#E6F2F3" />
				</linearGradient>
				<linearGradient id="b" x1="215.883" x2="62.709" y1="35.581" y2="142.763" gradientUnits="userSpaceOnUse">
					<stop stop-color="#9ACCCF" />
					<stop offset=".659" stop-color="#B4D8DB" />
					<stop offset="1" stop-color="#E6F2F3" />
				</linearGradient>
				<linearGradient id="d" x1="74.893" x2="96.323" y1="118.631" y2="118.576" gradientUnits="userSpaceOnUse">
					<stop stop-color="#121212" />
					<stop offset="1" stop-color="#014C52" />
				</linearGradient>
				<linearGradient id="f" x1="74.893" x2="96.323" y1="81.225" y2="81.17" gradientUnits="userSpaceOnUse">
					<stop stop-color="#121212" />
					<stop offset="1" stop-color="#014C52" />
				</linearGradient>
				<linearGradient id="g" x1="105.887" x2="201.051" y1="105.806" y2="101.453" gradientUnits="userSpaceOnUse">
					<stop stop-color="#121212" />
					<stop offset="1" stop-color="#014C52" />
				</linearGradient>
				<linearGradient id="h" x1="105.887" x2="156.218" y1="114.356" y2="113.14" gradientUnits="userSpaceOnUse">
					<stop stop-color="#121212" />
					<stop offset="1" stop-color="#014C52" />
				</linearGradient>
				<linearGradient id="i" x1="74.893" x2="96.323" y1="156.038" y2="155.983" gradientUnits="userSpaceOnUse">
					<stop stop-color="#121212" />
					<stop offset="1" stop-color="#014C52" />
				</linearGradient>
			</defs>
		</Icon>
	);
}
