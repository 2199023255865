import { ActivityIndicator, Button, Label, Radio, RadioGroup } from '@troon/ui';
import { For, Show, createSignal } from 'solid-js';
import { clientAction } from '@troon/api-client';
import { useAction } from '@solidjs/router';
import { Dynamic } from 'solid-js/web';
import { captureException } from '@sentry/solidstart';
import { useStripe } from '../../../../providers/stripe';
import { Elements } from '../../../../components/stripe/elements';
import { cardBrandToComponent } from '../../../../modules/credit-cards';
import type { ApiResponse } from '@troon/api-client';
import type { Stripe, StripeElements } from '@stripe/stripe-js';

function StripeCreatePaymentMethod(props: { onSuccess: (paymentMethodId: string) => void; clientSecret: string }) {
	const { stripe, elements } = useStripe();
	const [isLoading, setIsLoading] = createSignal(false);
	const [error, setError] = createSignal<string>();

	const addPaymentMethod = useAction(createPaymentMethod);

	const handleElementsSubmit = async (stripe: Stripe, elements: StripeElements) => {
		setIsLoading(true);
		setError(undefined);

		const paymentElement = elements.getElement('payment');
		if (!paymentElement) {
			setError('An error occurred. Please try again.');
			captureException(new Error('Stripe payment elements not initialized'));
			setIsLoading(false);
			return;
		}

		const { error: submitError } = await elements.submit();
		if (submitError) {
			const errorMessage = submitError.message || 'An error occurred';
			setError(errorMessage);
			setIsLoading(false);
			return;
		}

		try {
			const { error: createError, paymentMethod } = await stripe.createPaymentMethod({
				elements,
			});
			if (createError || !paymentMethod) {
				setError(createError?.message || 'An error occurred');
				return;
			}
			await stripe.confirmCardSetup(props.clientSecret, {
				payment_method: paymentMethod.id,
			});

			return paymentMethod.id;
		} catch (err) {
			const error = err as Error;
			setError(error.message || 'An error occurred');
			return null;
		} finally {
			setIsLoading(false);
		}
	};

	const handleFormSubmit = async (e: SubmitEvent) => {
		e.preventDefault();
		const currentStripe = stripe();
		const currentElements = elements();

		if (!currentStripe || !currentElements) {
			setError('An error occurred. Please try again.');
			captureException(new Error('Stripe not initialized'));
			return;
		}

		const paymentMethodId = await handleElementsSubmit(currentStripe, currentElements);
		if (!paymentMethodId) {
			setError('An error occurred. Please try again.');
			captureException(new Error('Missing payment method ID'));
			return;
		}

		try {
			const formData = new FormData();
			formData.append('payment_method_id', paymentMethodId);
			await addPaymentMethod(formData);
		} catch (error) {
			setError((error as Error).message || 'An error occurred');
		}

		props.onSuccess(paymentMethodId);
	};

	return (
		<form onSubmit={handleFormSubmit} class="flex flex-col gap-4">
			<div class="relative z-10 rounded border border-neutral p-4">
				<Show when={!stripe() || stripe.state === 'pending'}>
					<div class="absolute inset-0 flex items-center justify-center bg-white">
						<ActivityIndicator />
					</div>
				</Show>
				<Show when={stripe() && stripe.state !== 'errored'}>
					<Elements />
				</Show>
			</div>
			<Show when={error()}>
				<p class="text-red-500" role="alert">
					{error()}
				</p>
			</Show>
			<Button type="submit" disabled={isLoading() || !stripe()}>
				<Show when={isLoading()}>
					<ActivityIndicator aria-label="Loading" class="me-2 size-4" />
				</Show>
				Add Payment Method
			</Button>
		</form>
	);
}

type props = {
	data: ApiResponse<'get', '/v0/troon-access/payment-methods'> | undefined;
	selectedPaymentMethod: string | undefined;
	onSelect: (paymentMethodId: string) => void;
	onPaymentMethodAdded: (paymentMethodId: string) => void;
};

export function PaymentMethodSelector(props: props) {
	const [addPaymentMethod, setAddPaymentMethod] = createSignal(false);

	return (
		<div class="flex flex-col gap-3">
			<RadioGroup name="payment-method">
				<Label>Select Payment Method</Label>
				<Show when={props.data?.payment_methods} fallback={<ActivityIndicator />}>
					<div class="flex flex-col gap-2">
						<For each={props.data?.payment_methods}>
							{(method) => (
								<Radio
									value={method.id}
									checked={props.selectedPaymentMethod === method.id}
									onChange={(e) => props.onSelect(e.currentTarget.value)}
									class={`${
										props.selectedPaymentMethod === method.id
											? 'border-brand bg-brand-100'
											: 'border-neutral hover:border-neutral'
									} rounded-lg border p-4 transition-colors`}
								>
									<Show when={method.card}>
										{(card) => {
											return (
												<Label class="flex grow items-center justify-between">
													<div class="flex items-center gap-3">
														<Dynamic component={cardBrandToComponent[card().brand]!} class="w-10" />{' '}
														<div class="flex flex-col">
															<span class="font-medium">
																{card().brand} •••• {card().last4}
															</span>
															<span class="text-sm text-neutral-600">
																Expires {card().exp_month}/{card().exp_year}
															</span>
														</div>
													</div>
												</Label>
											);
										}}
									</Show>
								</Radio>
							)}
						</For>
					</div>
				</Show>
			</RadioGroup>
			<div
				class="cursor-pointer"
				onClick={() => {
					setAddPaymentMethod(true);
					props.onSelect('');
				}}
			>
				+ Add Payment Method
			</div>
			<div class={addPaymentMethod() ? 'block' : 'hidden'}>
				<StripeCreatePaymentMethod
					clientSecret={props.data?.client_secret ?? ''}
					onSuccess={(paymentMethodId) => {
						setAddPaymentMethod(false);
						props.onPaymentMethodAdded(paymentMethodId);
					}}
				/>
			</div>
		</div>
	);
}

const createPaymentMethod = clientAction(
	'POST',
	'/v0/troon-access/payment-methods',
	{},
	{ revalidate: '/v0/troon-access/payment-methods' },
);
